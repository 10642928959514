
import LoadDataGallery from '../components/LoadDataGallery'

const Mother = () => {
    return (
    <div>
        <h2 className="sectionTitle">Mother</h2>
        <LoadDataGallery name="personal" section="mother"/>
    </div>
    );
};

export default Mother;