import LoadDataGallery from '../components/LoadDataGallery'

const Editorialwork = () => {
    return (
    <div>
        <h2 className="sectionTitle">Editorial</h2>
        <LoadDataGallery name="editorial" section="portpeople"/>
    </div>
    );
};

export default Editorialwork;