
import LoadDataGallery from '../components/LoadDataGallery'

const Midlife = () => {
    return (
    <div>
        <h2 className="sectionTitle">Midlife</h2>
        <LoadDataGallery name="personal" section="midlife"/>
    </div>
    );
};

export default Midlife;