import LoadData from '../components/LoadData'

const Home = () => {
    return (
    <div className="home">
        <h2 className="sectionTitle"> </h2>
        <LoadData name="home" section="home"/>
    </div>
    );
};

export default Home;