
import LoadDataGallery from '../components/LoadDataGallery'

const Diaryofadancer = () => {
    return (
    <div>
        <h3 className="sectionTitle">Diary of a dancer</h3>
        <LoadDataGallery name="personal" section="diary"/>
    </div>
    );
};

export default Diaryofadancer;