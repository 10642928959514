import React, { useState, useEffect, useCallback } from "react";
import ImageGallery from "./ImageGallery";

const LoadDataGallery = (props) => {
  // load jason
  const [getData, setData] = useState([]);
  let jsonSub = props.section;
  let galleryImages= getData;
  let pathJson = '/data/'+props.name+'.json'
    
  useEffect(() => {
    fetch(pathJson)
    .then(function (res){
      return res.json();
    })
    .then (function (data) {
      setData(data[jsonSub]);
      console.log('loaded ', getData[jsonSub]);
    })
    .catch (function(err) {
      console.log(err, " error");
    });
  },[]);

  // let data = require('/public/data/'+props.name+'.json');
  // let jsonSub = props.section;
  // let galleryImages= getData;
  // let galleryImages= getData.data[jsonSub];
               
  ///state
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  /// swipe move state
  const [touchStart, setTouchStart, ] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50 

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    // add your conditional logic here
    isLeftSwipe ? nextSlide() : preveSlide();
  }
  
  ///// modal nav button functionality
  const handleOpenModal = (e, index) => {
    e.preventDefault();
    setSlideNumber(index);
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const preveSlide = useCallback(() => {
    slideNumber === 0 
    ? setSlideNumber( galleryImages.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }, [setSlideNumber, galleryImages.length, slideNumber]);

  const nextSlide = useCallback(() => {
    slideNumber + 1 === galleryImages.length
    ? setSlideNumber(0)
    : setSlideNumber(slideNumber + 1)
  }, [setSlideNumber, galleryImages.length, slideNumber]);

  const keydownHandler = useCallback((e) => {
    if(e.key === 'ArrowRight') nextSlide();
    if(e.key === 'ArrowLeft') preveSlide();
    if(e.key === 'Escape') handleCloseModal();
  },[nextSlide, preveSlide]);
  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    }
  }, [keydownHandler]);

  return (
    <div className="container">

      {/* modal */}
        {openModal &&
          <div className="sliderWrap"
            onTouchStart={onTouchStart} 
            onTouchMove={onTouchMove} 
            onTouchEnd={onTouchEnd} >
            <div className="fullScreenImage">
            <button onClick={e => handleCloseModal()} className="btn btnClose">X</button>
            <button onClick={e => preveSlide()} className="btn btnBack">&#0060;</button>
            <button onClick={e => nextSlide()} className="btn btnNext">&#0062;</button>
            {/* <span> Image Number {slideNumber+1}</span> */}
                  <ImageGallery 
                    key={galleryImages[slideNumber].key}
                    data={galleryImages[slideNumber]}
                    name={props.name}
                    section={jsonSub}
                    link={false}
                  />
                </div>
          </div>
        }
        {/* gallery */}
        <div className='galleryWrap'>
          {!openModal &&
            galleryImages && galleryImages.map((slide, index) =>{
              return(
                <div className='single' key={index}>
                  <ImageGallery 
                    key={index}
                    data={slide}
                    name={props.name}
                    section={props.section}
                    onChildClick={ e =>{handleOpenModal(e, index)}}
                    link={true}
                  />
                </div>
              )
            })
          }
        </div>        
      </div>
  );
};

export default LoadDataGallery;