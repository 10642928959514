
import LoadDataGallery from '../components/LoadDataGallery'

const Crisis = () => {
    return (
    <div>
       <h2 className="sectionTitle">Crisis</h2>
        <LoadDataGallery name="personal" section="crisis"/>
    </div>
    );
};

export default Crisis;