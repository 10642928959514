import { Routes, Route } from 'react-router-dom';
import Home from '../routes/Home';
import Layout from '../components/Layout'
import About from '../routes/About';
import Closer from '../routes/Closer';
import Diaryofadancer from '../routes/Diaryofadancer';
import Crisis from '../routes/Crisis';
import Mother from '../routes/Mother';
import Midlife from '../routes/Midlife';
// import Pain from '../routes/Pain';
import Editorialwork from '../routes/Editorialwork';
import Bio from '../routes/Bio';
import Contact from '../routes/Contact';

const App = () => {

  return (
    
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="closer" element={<Closer />} />
          <Route path="diaryofadancer" element={<Diaryofadancer />} />
          <Route path="crisis" element={<Crisis />} />
          {/* <Route path="pain" element={<Pain />} /> */}
          <Route path="mother" element={<Mother />} />
          <Route path="midlife" element={<Midlife />} />
          <Route path="editorialwork" element={<Editorialwork />} />
          <Route path="bio" element={<Bio />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<p>Not found!</p>} />
        </Route>
      </Routes>
    </>
  );
};

export default App;