const Bio = () => {
    return <div className="bio">
        <h2 className="sectionTitle">Bio</h2>
        <div className="main">
            <div className="copy">
            <h3>Elinor Carucci</h3>
            <br/>
            <p>
                                Born 1971 in Jerusalem, Israel, to a Mizrahi Jewish family of North African, Middle Eastern and Italian descent, Elinor Carucci graduated in 1995 from Bezalel Academy of Arts and Design with a degree in photography, and moved to New York that same year. In a relatively short amount of time, her work has been included in an impressive amount of solo and group exhibitions worldwide, solo shows include The Jewish Museum, Edwynn Houk gallery, Fifty One Fine Art Gallery, James Hyman and Gagosian Gallery, London among others and group show include The Museum of Modern Art New York and The Photographers' Gallery, London.
            </p><p>
                                Her photographs are included in the collections of The Museum of Modern Art New York, the Brooklyn Museum of Art, Houston Museum of Fine Art, among others and her work appeared in The New York Times Magazine, The New Yorker, Details, New York Magazine, W, Aperture, ARTnews and many more publications.
            </p><p>
                                She was awarded the International Center of Photography Infinity Award for Young Photographer in 2001, The Guggenheim Fellowship in 2002 and NYFA in 2010. Carucci has published five monographs to date, Closer, Chronicle Books 2002, Diary of a dancer, SteidlMack 2005, MOTHER, Prestel 2013, Midlife, Monacelli Press 2019, and The Collars of RBG, Clarkson Potter Random House, 2023.
            </p><p>
                                Carucci teaches at the graduate program of Photography and Related Media at School of Visual Arts and is represented by Edwynn Houk Gallery.
            </p>
           </div>
        </div>
        <div className="image">
            <img src="images/Elinor-by-Richard-Renaldi.jpg" ALIGN="left" HSPACE="50" VSPACE="50" alt="Elinor-by-Richard-Renaldi"/>
            <span>Photograph by Richard Renaldi</span>
        </div>
    </div>
};

export default Bio;