import React, { Component } from "react";

let section;

class LoadData extends Component {
  constructor(props){
      super(props);
      section = props.name;
      this.state = {
        main : [],
        updates: []
      };
  }

  componentDidMount(){
      // console.log("interestList: " + './data/'+section+'.json');
      fetch('data/'+section+'.json')
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ main : responseJson.main });
              this.setState({ updates : responseJson.updates });
            })
            .catch((error) => {
              console.error(error);
            });
  }

  render() {
      return(
        <div className="container">
                <div className="main fifty">
                 {this.state.main.map((entery) =>{
                  return(
                    <div className="entery" key={entery.id}>
                      <a href={entery.link} className=""  target="_blank" rel="noreferrer"> 
                        <img src={`./images/${section}/${entery.image}`} alt='elinor carucci home page'/>
                      </a>
                      <a href={entery.link} className="box" target="_blank" rel="noreferrer">{entery.linkCopy}&nbsp;&nbsp;&#8594;</a> 
                    </div>
                  )        
                })}
              </div>
              <div className="updates fifty">
              {this.state.updates.map((entery) =>{
                var withLink = <a href={entery.link}  target="_blank" rel="noreferrer"><div dangerouslySetInnerHTML={{__html:  `${entery.desc}<b>&nbsp;&nbsp;&#8594;</b>`}} /></a>
                var withoutLink = <div dangerouslySetInnerHTML={{__html:  `${entery.desc}`}} />
                  return(
                    <div className="entery" key={entery.id}>
                      {entery.link ? withLink : withoutLink}
                    </div>
                  )        
                })}
              </div> 
            </div>
      )
  }
}
export default LoadData;


// CACHED VERSION OF LOADING
// const LoadData = (props) => {

//   // load jason
//   let section = props.name;
//   let data = require('/public/data/'+section+'.json');
//   console.log('props.name ',section);

  
//   return (

//     <div className="container">
//       <div className="main fifty">
//         {data['main'].map((entery) =>{
//           return(
//             <div className="entery" key={entery.id}>
//               <a href={entery.link} className=""  target="_blank" rel="noreferrer"> 
//                 <img src={`./images/${section}/${entery.image}`} alt='elinor carucci home page'/>
//               </a>
//               <a href={entery.link} className="box" target="_blank" rel="noreferrer">{entery.linkCopy}&nbsp;&nbsp;&#8594;</a> 
//             </div>
//           )        
//         })}
//       </div>
//       <div className="updates fifty">
//       {data['updates'].map((entery) =>{
//         var withLink = <a href={entery.link}  target="_blank" rel="noreferrer"><div dangerouslySetInnerHTML={{__html:  `${entery.desc}<b>&nbsp;&nbsp;&#8594;</b>`}} /></a>
//         var withoutLink = <div dangerouslySetInnerHTML={{__html:  `${entery.desc}`}} />
//           return(
//             <div className="entery" key={entery.id}>
//               {entery.link ? withLink : withoutLink}
//             </div>
//           )        
//         })}
//       </div>
//     </div>
//   );
// };

// export default LoadData;