export const menuItems = [
  {
    title: 'Personal work',
    url: '/',
    submenu: [
      {
        title: 'Closer',
        url: 'closer',
      },
      {
        title: 'Diary of a dancer',
        url: 'diaryofadancer',
        // submenu: [
        //   {
        //     title: 'Frontend',
        //     url: 'frontend',
        //   },
        //   {
        //     title: 'Backend',
        //     submenu: [
        //       {
        //         title: 'NodeJS',
        //         url: 'node',
        //       },
        //       {
        //         title: 'PHP',
        //         url: 'php',
        //       },
        //     ],
        //   },
        // ],
      },
      // {
      //   title: 'Pain',
      //   url: 'pain',
      // },
      {
        title: 'Crisis',
        url: 'crisis',
      },
      {
        title: 'Mother',
        url: 'mother',
      },
      ,
      {
        title: 'Midlife',
        url: 'midlife',
      }
    ],
  },
  {
    title: 'Editorial work',
    url: '/editorialwork',
  },
  ,
  {
    title: 'Info',
    url: '/cvbio',
    submenu: [
      {
        title: 'Bio',
        url: 'bio',
      },
      {
        title: 'Contact/Info.',
        url: 'contact',
      },
    ],
  },
];