import React, {useState, useCallback} from "react";

const ImageGallery = ({ data, name, section, onChildClick, link }) => {
  let element;
  let title;

  const [loaded, setLoaded] = useState(false);
  const onLoad = useCallback(() => {
    console.log('loaded');
    setLoaded(true);
  }, [])

  if(link) {
    // var rootClassName = classNames(className, 'image', {
    //   'image-loaded': loaded,
    // });
    element = <a onClick={onChildClick} href="/">
              <img 
              className={(loaded===true)? 'image-loaded': 'image'}
              // style={{display: loaded? 'opacity': '1'}} 
              onLoad={onLoad} 
              src={`${window.location.origin}/images/${name}/${section}/images/${data.image}`} 
              alt={data.desc}
              />
            </a>
    } else {
      element = <img src={`${window.location.origin}/images/${name}/${section}/images/${data.image}`} alt={data.desc}/>
      title = <div className="title"><b><i>{data.title}</i> {data.year}</b></div>
    };

  return (
    <div className={`${link ? 'yes' : 'no'}`}>
      {element}
      {title}
    </div>
  );
};

export default ImageGallery;