import { Link } from "react-router-dom";

const Contact = () => {
    const ButtonMailto = ({ mailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = mailto;
                    e.preventDefault();
                }}
            >
                {label}
            </Link>
        );
    };


    return <div className="contact">
        <h2 className="sectionTitle">CV/Contact</h2>
    <ul>
        <li>
            <a href="http://elinorcarucci.com/elinorCarucciCV.pdf">
                <h3>Elinor Carucci CV &nbsp;&nbsp;&#8594;</h3>
            </a>
        </li>
        <li>
            <h3>Elinor Carucci</h3>
            <ButtonMailto label="caruccielinor@gmail.com &nbsp;&nbsp;&#8594;" mailto="mailto:caruccielinor@gmail.com" />
        </li>
        <li>
            <h3>Edwynn Houk Gallery - Julie Castellano</h3>
            <ButtonMailto label="julie@houkgallery.com &nbsp;&nbsp;&#8594;" mailto="mailto:julie@houkgallery.com" />
        </li>
        <li>
            <h3>Atrbute Agency - Emily Leonardo</h3>
            <ButtonMailto label="Emily@atrbute.com &nbsp;&nbsp;&#8594;" mailto="mailto:Emily@atrbute.com" />
        </li>
    </ul>
    </div>
};

export default Contact;